import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import global_ from './config/global.js'
import http from './api/request'
import $ from 'jquery'
import {setCookie, getCookie, delCookie} from './cookie.js';
import echarts from 'echarts'
Vue.prototype.$http = http
Vue.prototype.GLOBAL = global_;
Vue.prototype.$ = $;
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
Vue.prototype.$cookie = {
  setCookie,
  getCookie,
  delCookie
}
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
