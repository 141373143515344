// 正式环境
var base_url = 'https://admin.iguolao.com';
var new_url = 'https://apipro.guolaoai.com';
var h5_url = 'https://h5.guolaoai.com';
var win_url = 'https://wp.iguolao.com';
var share_url = 'https://h5.guolaoai.com/shareA'
export default {
  base_url,
  new_url,
  h5_url,
    win_url,
    share_url
}

// 测试环境
// var base_url = 'https://admin.iguolao.com';
// var new_url = 'https://apitest.guolaoai.com';
// var h5_url = 'http://h5test.guolaoai.com';
// var win_url = 'http://wp-test.iguolao.com';
// var share_url = 'http://h5test.guolaoai.com/shareA'
// export default {
//   base_url,
//   new_url,
//   h5_url,
//   win_url,
//   share_url
// }
